/*
|==========================================================================
| Isotope Masonry Grid
|==========================================================================
| 
| This function activates a grid based masonry layout that can be
| customized per project using the Isotope plugin.
|
|==========================================================================
| @reference: https://isotope.metafizzy.co/
|
*/

// Activates grid based masonry layout
function isotope_masonry() {

  // jQuery('.grid').isotope({
  //   itemSelector: '.grid-item',
  //   percentPosition:true,
  //   masonry: { 
  //     columnWidth: '.grid-sizer'
  //   }
  // }); 

  // jQuery('.grid-item').click(function(event){
  //   var this_grid_item = jQuery(this);
  //   var link_to_go_to = this_grid_item.find('a').attr('href');
  //   external_redirect(link_to_go_to);
  //   load_content(link_to_go_to);
  // }); 

  // jQuery('.filter-button-group').on( 'click', 'button', function() {
  //   var filterValue = jQuery(this).attr('data-filter');
  //   jQuery('.grid').isotope({ filter: filterValue });
  // });

  // setTimeout(function(){
  //   jQuery("#showallposts").click();
  // }, 350);

  var $grid = jQuery('.grid').isotope({
    itemSelector: '.grid-item',
    percentPosition: true,
    masonry: {
      columnWidth: '.grid-sizer'
    }

  });

  // store filter for each group
  var filters = [];

  jQuery('.sort-controll-container').on('change', '.filters-select', function (event) {

    var $select = jQuery(this);
    // get group key
    var filterGroup = $select.attr('data-filter-group');
    // set filter for group
    filters[filterGroup] = $select.val();
    // combine filters
    var filterValue = concatValues(filters);


    // jQuery('.news-categories-menu a').removeClass('active-filter');
    // jQuery(this).addClass('active-filter');
    // var filterValue = jQuery(this).attr('data-filter');

    $grid.isotope({ filter: filterValue });
    //jQuery('.overflow-container').scrollTo(jQuery(this), 1000, {easing:'easeInOutCubic'} );
  });

  function concatValues(obj) {
    var value = '';
    for (var prop in obj) {
      value += obj[prop];
    }
    return value;
  }

}